import React, { useState } from "react";
import { connect } from "react-redux";
import * as Actions from "../store/actions";
import { isArrayNullOrEmpty, isNullOrEmpty } from "../../tools";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import DialogPaymentCharacteristic from "../components/DialogPaymentCharacteristic";
import CardError from "components/Card/CardError";
import { Trans } from "@lingui/macro";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent } from "@material-ui/core";
import { isIbanValid } from "tools";

function PaymentCharacteristicDetail({ paymentCharacteristicCtx, setPaymentCharacteristic, closePaymentCharacteristic, classes }) {
  const [errors, setErrors] = useState([]);

  var paymentCharacteristic = paymentCharacteristicCtx.paymentCharacteristic;
  var supplier = paymentCharacteristicCtx.supplier;
  var supplierSjs = paymentCharacteristicCtx.supplierSjs;
  var thirdParty = paymentCharacteristicCtx.thirdParty;

  if (paymentCharacteristicCtx.error) {
    return (
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={true}
        onClose={() => closePaymentCharacteristic()}
        aria-labelledby="form-dialog-title"
        classes={{ paper: classes.dialogPaper }}
        scroll="paper"
      >
        <DialogContent
          style={{
            ...DialogInlineStyleDetail.dialogContent
          }}
          dividers={true}
        >
          <CardError error={paymentCharacteristicCtx.error} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => closePaymentCharacteristic()} color="info">
            <Trans> Close </Trans>
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else if (paymentCharacteristicCtx.isLoading) {
    return (
      <Dialog fullWidth={true} maxWidth="md" open={true} aria-labelledby="form-dialog-title" classes={{ paper: classes.dialogPaper }} scroll="paper">
        <DialogContent
          style={{
            ...DialogInlineStyleDetail.dialogContent
          }}
          dividers={true}
        >
          <center>
            <p>
              <Trans>Fetching data</Trans>
            </p>
            <p>
              <CircularProgress />
            </p>
          </center>
        </DialogContent>
      </Dialog>
    );
  }

  const savePayChar = function(payChar) {
    if (payChar.cancel) {
      closePaymentCharacteristic();
      return;
    }

    let err = [];
    if (!payChar.startOfValidityDate) {
      err.push({ code: "startOfValidityDate" });
    }
    if (isNullOrEmpty(payChar.iban) || !isIbanValid(payChar.iban)) {
      err.push({ code: "iban" });
    }
    if (payChar.thirdPartyContact) {
      let contact = payChar.thirdPartyContact;
      for (var i = 0; i < contact.details.length; i++) {
        let detail = contact.details[i];
        if (isNullOrEmpty(detail.value)) continue;

        if (detail.contactDetailTypeCode === "MAIL") {
          let re = /\S+@\S+\.\S+/;
          if (!re.test(detail.value)) {
            err.push({ code: "MAIL" });
          }
        } else {
          let re = /\d+/;
          if (!re.test(detail.value)) {
            err.push({ code: "PHONE" });
          }
        }
      }
    }

    if (err.length > 0) {
      setErrors(err);
    } else {
      setPaymentCharacteristic(payChar);
    }
  };

  return (
    <DialogPaymentCharacteristic
      thirdParty={thirdParty}
      supplier={supplier}
      supplierSjs={supplierSjs}
      paymentCharacteristic={paymentCharacteristic}
      setPaymentCharacteristic={savePayChar}
      errors={errors}
    />
  );
}

const DialogInlineStyleDetail = {
  dialogContent: {
    padding: "0px 10px 0px",
    height: "90%"
  },
  dialogPaper: {
    minHeight: "90vh",
    maxHeight: "90vh"
  }
};

const mapStateToProps = state => {
  return {
    paymentCharacteristicCtx: !isArrayNullOrEmpty(state.TP_PaymentCharacteristics)
      ? state.TP_PaymentCharacteristics[state.TP_PaymentCharacteristics.length - 1].paymentCharacteristicCtx
      : null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    closePaymentCharacteristic: () => {
      dispatch(Actions.ClosePaymentCharacteristic());
    },
    setPaymentCharacteristic: payChar => {
      dispatch(Actions.SavePaymentCharacteristic(payChar));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(PaymentCharacteristicDetail));

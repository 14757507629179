import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import { useLingui } from "@lingui/react";
import { t, Trans } from "@lingui/macro";
import ThirdPartySelectorNew from "components/Common/ThirdPartySelectorNew";
import { ThirdPartyGet, ThirdPartyGetFromAltares, ThirdPartyGetFromInfolegale } from "../../../tpCopernic/thirdParties/actions/ThirdPartyActions";
import CardError from "components/Card/CardError";
import { isNullOrEmpty } from "tools";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

const RequestSupplierThirdParty = ({ request, setRequest, isReadOnly, errors }) => {
  const [state, setState] = useState({ isLoading: false, error: null });
  const { i18n } = useLingui();

  const error = code => errors && errors.some(e => e.code === code);

  useEffect(() => {
    if (!request.linkedThirdParty && !isNullOrEmpty(request.thirdPartySource)) {
      if (request.thirdPartySource.toUpperCase() === "ALTARES" && !isNullOrEmpty(request.thirdPartyDuns)) {
        setState({ isLoading: true, error: null });
        ThirdPartyGetFromAltares(request.thirdPartyDuns, true)
          .then(tp => {
            setState({ isLoading: false });
            var thirdParty = tp.thirdParty;
            if (request.thirdPartyLanguageCode) thirdParty.languageCode = request.thirdPartyLanguageCode;
            if (request.thirdPartyCustomLongName) thirdParty.customLongName = request.thirdPartyCustomLongName;
            if (request.thirdPartyIsHandicapSector) thirdParty.isHandicapSector = request.thirdPartyIsHandicapSector;
            setThirdParty({ ...thirdParty, isSupplier: tp.isSupplier, isPointOfSale: tp.isPointOfSale });
          })
          .catch(e => setState({ isLoading: false, error: e }));
      } else if (
        request.thirdPartySource.toUpperCase() === "INFOLEGALE" &&
        !isNullOrEmpty(request.thirdPartyLegalIdentifier) &&
        request.thirdPartyLegalIdentifier.length === 14
      ) {
        setState({ isLoading: true, error: null });
        ThirdPartyGetFromInfolegale(request.thirdPartyLegalIdentifier, true)
          .then(tp => {
            setState({ isLoading: false });
            var thirdParty = tp.thirdParty;
            if (request.thirdPartyLanguageCode) thirdParty.languageCode = request.thirdPartyLanguageCode;
            if (request.thirdPartyCustomLongName) thirdParty.customLongName = request.thirdPartyCustomLongName;
            if (request.thirdPartyIsHandicapSector) thirdParty.isHandicapSector = request.thirdPartyIsHandicapSector;
            setThirdParty({ ...thirdParty, isSupplier: tp.isSupplier, isPointOfSale: tp.isPointOfSale });
          })
          .catch(e => setState({ isLoading: false, error: e }));
      } else if (request.thirdPartySource.toUpperCase() === "MANUAL" && request.thirdPartyIdentifier > 0) {
        setState({ isLoading: true, error: null });
        ThirdPartyGet(request.thirdPartyIdentifier, true)
          .then(tp => {
            setState({ isLoading: false });
            var thirdParty = tp.thirdParty;
            if (request.thirdPartyCustomLongName) thirdParty.customLongName = request.thirdPartyCustomLongName;
            setThirdParty({ ...thirdParty, isSupplier: tp.isSupplier, isPointOfSale: tp.isPointOfSale });
          })
          .catch(e => setState({ isLoading: false, error: e }));
      } else {
        setState({ isLoading: false, error: "ThirdParty is not recognized" });
      }
    }
  }, [
    request.linkedThirdParty,
    request.thirdPartyIdentifier,
    request.thirdPartyDuns,
    request.thirdPartyLegalIdentifier,
    request.thirdPartyLanguageCode,
    request.thirdPartyCustomLongName,
    request.thirdPartyIsHandicapSector
  ]);

  const setThirdParty = tp => {
    if (!tp) {
      setRequest({
        ...request,
        thirdPartyIdentifier: null,
        thirdPartyStatus: null,
        thirdPartyLegalIdentifier: null,
        thirdPartyDuns: null,
        thirdPartyLongName: null,
        thirdPartySource: null,
        thirdPartyLanguageCode: null,
        thirdPartyCustomLongName: null,
        thirdPartyIsHandicapSector: null,
        linkedThirdParty: null,
        supplierCoupa: null
      });
      return;
    }
    setRequest({
      ...request,
      thirdPartyIdentifier: tp.identifier === 0 ? null : tp.identifier,
      thirdPartyStatus: tp.thirdPartyStatusCode,
      thirdPartyLegalIdentifier: tp.legalIdentifier,
      thirdPartyDuns: tp.duns,
      thirdPartyLongName: tp.longName,
      thirdPartySource: tp.isFromAltares ? "ALTARES" : tp.isFromInfolegal ? "INFOLEGALE" : "MANUAL",
      thirdPartyLanguageCode: tp.languageCode,
      thirdPartyCustomLongName: tp.customLongName,
      thirdPartyIsHandicapSector: tp.isHandicapSector,
      linkedThirdParty: tp,
      supplierCoupa:
        request.contextCode === "advancePurchase" ? null : parseInt(request.supplierCoupa?.idMdm) === tp.identifier ? request.supplierCoupa : null
    });
  };

  const ConvertThirdPartyStatus = tp => {
    if (!tp) return "";
    if (!!tp?.isSupplier) return i18n._(t`Workflow_Supplier_KnownAsSupplier`);
    if (!!tp?.isPointOfSale) return i18n._(t`Workflow_Supplier_KnownAsPointOfSale`);
    return i18n._(t`Workflow_Supplier_NotReferenced`);
  };

  if (state.error) return <CardError error={state.error} />;

  if (state.isLoading) return <CircularProgress />;

  var errorThirdParty;
  if (error("legalIdentifier_invalid")) {
    errorThirdParty = <Trans>LegalIdentifier_Invalid</Trans>;
  } else if (error("legalIdentifier_closed")) {
    errorThirdParty = <Trans>ThirdParty_Status_Closed</Trans>;
  } else if (error("SupplierAlreadyAssociated")) {
    errorThirdParty = <Trans>SupplierAlreadyAssociated</Trans>;
  }

  return (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>
          <Trans>Supplier legal identifier</Trans>
        </h4>
      </CardHeader>
      <CardBody>
        <ThirdPartySelectorNew
          isEditable={!isReadOnly}
          isLanguageEditable={!isReadOnly}
          thirdParty={request.linkedThirdParty}
          setThirdParty={tp => {
            setThirdParty(tp);
          }}
          errorMessage={errorThirdParty}
          error={error("legalIdentifier")}
          title={ConvertThirdPartyStatus(request.linkedThirdParty)}
          showIsHandicapSector={true}
        />
      </CardBody>
    </Card>
  );
};

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};

export default RequestSupplierThirdParty;

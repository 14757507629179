import { Trans } from "@lingui/macro";
import { Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { SearchOutlined, ViewDay, ViewHeadline, ViewQuilt } from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import CountryAutocomplete from "components/Autocomplete/CountryAutocomplete";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import OldStructureAutocomplete from "components/Autocomplete/OldStructureAutocomplete";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem.jsx";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import LocalStorageHelper from "services/common/LocalStorageHelper";
import { CommonCountryState, LocationNature, LocationStatus, LocationType } from "store/MasterValue/MasterValueTypes";
import * as Actions from "../store/actions";

function getDefaultCountry() {
  var defaultCountry = "FR";
  var localStorageHelper = new LocalStorageHelper();
  var getPref = localStorageHelper.get("Preferences");
  if (getPref !== null) {
    defaultCountry = getPref.InfoGeneral.Country;
  }

  return defaultCountry;
}

function SiteSearch({ searchCtx, showSearchCriteriaOnSideBar, searchSites, classes, defaultLang }) {
  const initialCriteria = { searchMode: "Simple" };
  const [criteria, setCriteria] = useState(initialCriteria);

  useEffect(() => {
    setCriteria(
      searchCtx && searchCtx.criterias
        ? searchCtx.criterias
        : { ...initialCriteria, criteriaCountry: getDefaultCountry(), siteStatuses: ["ACTIVE"], siteNatures: ["COLAS"] }
    );
  }, [searchCtx]);

  const cleanCriteria = () => {
    setCriteria({ ...initialCriteria, criteriaCountry: getDefaultCountry(), siteStatuses: ["ACTIVE"], siteNatures: ["COLAS"] });
  };

  var searchGridSize = 4;
  if (showSearchCriteriaOnSideBar) searchGridSize = 12;

  const blockHeaderSearchClasses = ["blockHeaderSearch"];
  if (showSearchCriteriaOnSideBar) blockHeaderSearchClasses.push("Reduce");

  const simpleSearchClasses = ["simple-search"];
  const extendedSearchClasses = ["extended-search"];
  const complexSearchClasses = ["complex-search"];
  var searchTitle = "";

  switch (criteria.searchMode) {
    case "Simple":
      simpleSearchClasses.push("show");
      if (!showSearchCriteriaOnSideBar) searchTitle = <Trans> Quick Search </Trans>;
      break;
    case "Extend":
      extendedSearchClasses.push("show");
      if (!showSearchCriteriaOnSideBar) searchTitle = <Trans> Extended Search</Trans>;
      break;
    case "Complex":
      complexSearchClasses.push("show");
      if (!showSearchCriteriaOnSideBar) searchTitle = <Trans> Complex Search</Trans>;
      break;
    default:
      console.warn("Unknown searchMode: " + criteria.searchMode);
      break;
  }

  var countrySimpleSearchWidth = 3;
  var simpleSearchWidth = 9;
  if (showSearchCriteriaOnSideBar) {
    countrySimpleSearchWidth = 12;
    simpleSearchWidth = 12;
  }

  var launchSearch = e => {
    e.preventDefault();

    var localStorageHelper = new LocalStorageHelper();
    var getPref = localStorageHelper.get("Preferences");
    var pageSize = 10;
    if (!!getPref && !!getPref.Pref && !!getPref.Pref.ResultPageSize) {
      pageSize = getPref.Pref.ResultPageSize;
    }

    var tempCriteria = {
      ...criteria,
      startPage: 0,
      pageSize: pageSize,
      orderProperty: null,
      isDescendingOrder: false
    };

    searchSites(tempCriteria);
  };

  var selectedStructure = null;
  if (!!criteria.criteriaStructureId && !!criteria.criteriaStructureType) {
    selectedStructure = { structureId: criteria.criteriaStructureId, structureType: criteria.criteriaStructureType };
  }

  return (
    <Card>
      <CardHeader color="info" icon>
        <CardIcon color="info">
          <SearchOutlined />
        </CardIcon>
        <div className={blockHeaderSearchClasses.join(" ")}>
          <div className="blockHeaderSearchItem">
            <ToggleButtonGroup value={criteria.searchMode} exclusive onChange={(e, mode) => setCriteria({ ...criteria, searchMode: mode })}>
              <ToggleButton value="Simple">
                <ViewDay />
              </ToggleButton>
              <ToggleButton value="Extend">
                <ViewHeadline />
              </ToggleButton>
              <ToggleButton value="Complex">
                <ViewQuilt />
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div className="blockHeaderSearchItem">
            <Typography variant="body1">{searchTitle}</Typography>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div className={simpleSearchClasses.join(" ")}>
          <form onSubmit={launchSearch} autoComplete="off">
            <GridContainer>
              <GridItem xs={countrySimpleSearchWidth} sm={countrySimpleSearchWidth} md={countrySimpleSearchWidth}>
                <CountryAutocomplete
                  countryCode={criteria.criteriaCountry}
                  onChange={countryCode => setCriteria({ ...criteria, criteriaCountry: countryCode, criteriaCountryState: null })}
                  defaultLang={defaultLang}
                  isEditable
                />
              </GridItem>
              <GridItem xs={simpleSearchWidth} sm={simpleSearchWidth} md={simpleSearchWidth}>
                <CustomInput
                  labelText={<Trans>Site_FreeSearch</Trans>}
                  id="criteriaFullTextSearch"
                  onChange={e => setCriteria({ ...criteria, criteriaFullText: e.target.value })}
                  value={!!criteria.criteriaFullText ? criteria.criteriaFullText : ""}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
            </GridContainer>
            <div className={classes.flexRight}>
              <Button onClick={cleanCriteria}>
                <Trans> Clean </Trans>
              </Button>
              <Button type="submit" color="info">
                <Trans> Search </Trans>
              </Button>
            </div>
          </form>
        </div>
        <div className={extendedSearchClasses.join(" ")}>
          <form onSubmit={launchSearch} autoComplete="off">
            <GridContainer>
              <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
                <CustomInput
                  labelText={<Trans>Site_Id</Trans>}
                  id="criteriaMdmId"
                  onChange={e => {
                    setCriteria({ ...criteria, criteriaMdmId: e.target.value });
                  }}
                  value={!!criteria.criteriaMdmId ? criteria.criteriaMdmId : ""}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
                <CustomInput
                  labelText={<Trans>Site_Name</Trans>}
                  id="criteriaSiteName"
                  onChange={e => setCriteria({ ...criteria, criteriaName: e.target.value })}
                  value={!!criteria.criteriaName ? criteria.criteriaName : ""}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
                <CustomInput
                  labelText={<Trans>Site_Address</Trans>}
                  id="criteriaAddress"
                  onChange={e => setCriteria({ ...criteria, criteriaAddress: e.target.value })}
                  value={!!criteria.criteriaAddress ? criteria.criteriaAddress : ""}
                  formControlProps={{
                    fullWidth: true
                  }}
                />
              </GridItem>
              <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
                <MasterValueAutocomplete
                  prefixCode
                  isEditable
                  isRequired={false}
                  formatText={s => (s.abbreviation ? `${s.label} (${s.abbreviation})` : s.label)}
                  label={<Trans>State</Trans>}
                  mvType={CommonCountryState}
                  mvFilter={s => s.countryCode === criteria.criteriaCountry}
                  selectedValue={criteria.criteriaCountryState}
                  onValueChange={code => setCriteria({ ...criteria, criteriaCountryState: code })}
                  disabledIfEmpty
                />
              </GridItem>
              <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
                <CountryAutocomplete
                  label={<Trans>Country</Trans>}
                  countryCode={criteria.criteriaCountry}
                  onChange={countryCode => setCriteria({ ...criteria, criteriaCountry: countryCode, criteriaCountryState: null })}
                  defaultLang={defaultLang}
                  isEditable
                />
              </GridItem>
              <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
                <MasterValueAutocomplete
                  multiple
                  mvType={LocationStatus}
                  selectedValue={!!criteria.siteStatuses ? criteria.siteStatuses : []}
                  onValueChange={value => setCriteria({ ...criteria, siteStatuses: value })}
                  label={<Trans>Site_Statuses</Trans>}
                  isEditable
                />
              </GridItem>
              <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
                <MasterValueAutocomplete
                  multiple
                  mvType={LocationType}
                  selectedValue={!!criteria.siteTypes ? criteria.siteTypes : []}
                  onValueChange={value => setCriteria({ ...criteria, siteTypes: value })}
                  label={<Trans>Site_Types</Trans>}
                  isEditable
                />
              </GridItem>
              <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
                <OldStructureAutocomplete
                  value={selectedStructure}
                  onChange={(structureId, structureType) =>
                    setCriteria({
                      ...criteria,
                      criteriaStructureId: structureId ? structureId : null,
                      criteriaStructureType: structureType ? structureType : null
                    })
                  }
                  defaultLang={defaultLang}
                />
              </GridItem>
              <GridItem xs={searchGridSize} sm={searchGridSize} md={searchGridSize}>
                <MasterValueAutocomplete
                  multiple
                  mvType={LocationNature}
                  selectedValue={!!criteria.siteNatures ? criteria.siteNatures : []}
                  onValueChange={value => setCriteria({ ...criteria, siteNatures: value })}
                  label={<Trans>Site_Nature</Trans>}
                  isEditable
                />
              </GridItem>
            </GridContainer>
            <div className={classes.flexRight}>
              <Button onClick={cleanCriteria}>
                <Trans> Clean </Trans>
              </Button>
              <Button type="submit" color="info">
                <Trans>Search</Trans>
              </Button>
            </div>
          </form>
        </div>
      </CardBody>
    </Card>
  );
}

const mapStateToProps = state => {
  return {
    searchCtx: state.ST_Search
  };
};

const mapDispatchToProps = dispatch => {
  return {
    searchSites: criterias => {
      dispatch(Actions.SearchSites(criterias));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(thirdPartySearchCriteriaStyle)(SiteSearch));

import * as Actions from "./types";
import { isArrayNullOrEmpty, isNull } from "../../../../tools";
import { ApimGet, ApimMultiplePost, ApimPost } from "components/Common/ApimSender";
import { HasRight } from "services/user/UserHelper";

export function GetSupplier(thirdPartyId) {
  return (dispatch, _) => {
    ApimGet(
      "api/TpCopernic/Suppliers/" + thirdPartyId,
      responseJson => {
        dispatch({
          type: Actions.TP_COPERNIC_SUPPLIER_GET,
          supplier: responseJson,
          isEditable: HasRight("thirdparty_supplier.edit"),
          isEditableDemat: HasRight("thirdparty_supplier.edit_demat"),
          isEditableSj: HasRight("thirdparty_supplier.edit_sj"),
          isEditablePayChar: HasRight("thirdparty_supplier.edit_iban"),
          canAddSj: HasRight("thirdparty_supplier.add_sj"),
          isEditableCoupa: HasRight("thirdparty_supplier.edit_coupa"),
          isEditablePayPeriod: HasRight("thirdparty_supplier.edit_payper")
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.TP_COPERNIC_SUPPLIER_ERROR,
          error: error
        });
      }
    );
  };
}

export function EditSupplier(supplier) {
  return dispatch => {
    return dispatch({
      type: Actions.TP_COPERNIC_SUPPLIER_EDITVALUE,
      supplier: supplier
    });
  };
}

function crupdateSupplier(supplier, reloadThirdParty, dispatch) {
  ApimPost(
    "api/TpCopernic/Suppliers",
    supplier,
    responseJson => {
      dispatch({
        type: Actions.TP_COPERNIC_SUPPLIER_SAVE_END,
        successType: reloadThirdParty ? "SUCCESS_RELOAD" : "SUCCESS",
        supplier: responseJson
      });
    },
    error => {
      console.error(error);
      dispatch({
        type: Actions.TP_COPERNIC_SUPPLIER_ERROR,
        error: error
      });
    }
  );
}

export function SaveSupplier(supplier, thirdParty, reloadThirdParty) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.TP_COPERNIC_SUPPLIER_SAVE_BEGIN
    });

    if (thirdParty?.identifier === 0) {
      ApimPost(
        "api/TpCopernic/ThirdParties",
        thirdParty,
        responseJson => {
          supplier.thirdPartyIdentifier = responseJson.identifier;
          crupdateSupplier(supplier, reloadThirdParty, dispatch);
        },
        error => {
          console.error(error);
          dispatch({
            type: Actions.TP_COPERNIC_SUPPLIER_ERROR,
            error: error
          });
        }
      );
    } else {
      crupdateSupplier(supplier, reloadThirdParty, dispatch);
    }
  };
}

export function GenerateNotificationSupplier(supplier, callbackFn, errorFn) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.TP_COPERNIC_SUPPLIER_NOTIFICATION_BEGIN
    });

    ApimPost(
      "api/TpCopernic/Suppliers/GenerateNotification",
      supplier,
      responseJson => {
        callbackFn();
        dispatch({
          type: Actions.TP_COPERNIC_SUPPLIER_NOTIFICATION_END
        });
      },
      error => {
        errorFn(error.response.data);
        dispatch({
          type: Actions.TP_COPERNIC_SUPPLIER_NOTIFICATION_ERROR,
          error: error
        });
      }
    );
  };
}

/// ------------------------------------------------------------------------------------------------------
/// SJ ACTIONS
/// ------------------------------------------------------------------------------------------------------
export function SupplierSjAdd(sj) {
  return dispatch => {
    return dispatch({
      type: Actions.TP_COPERNIC_SUPPLIER_SJ_ADD,
      sj: sj
    });
  };
}

export function SupplierSjUpdate(sj) {
  return dispatch => {
    return dispatch({
      type: Actions.TP_COPERNIC_SUPPLIER_SJ_UPD,
      sj: sj
    });
  };
}

export function SupplierSjSave(supplierId, sjs) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.TP_COPERNIC_SUPPLIER_SJ_SAVE_BEGIN
    });

    const url = "api/TpCopernic/Suppliers/" + supplierId + "/sjs";
    var urlData = sjs
      .filter(sj => sj.action !== "NONE")
      .map(sj => {
        return { url: url, data: sj.sj };
      });

    ApimMultiplePost(
      urlData,
      () => {
        ApimGet(
          url,
          sjs => {
            dispatch({
              type: Actions.TP_COPERNIC_SUPPLIER_SJ_SAVE_END,
              sjs: sjs
            });
          },
          e =>
            dispatch({
              type: Actions.TP_COPERNIC_SUPPLIER_ERROR,
              error: e
            })
        );
      },
      e =>
        dispatch({
          type: Actions.TP_COPERNIC_SUPPLIER_ERROR,
          error: e
        }),
      () => {
        /* SKIP */
      },
      error => {
        dispatch({
          type: Actions.TP_COPERNIC_SUPPLIER_ERROR,
          error: error
        });
      }
    );
  };
}

/// ------------------------------------------------------------------------------------------------------
/// COUPA SUPPLIER ACTIONS
/// ------------------------------------------------------------------------------------------------------
export function SupplierCoupaGet(supplierId) {
  return (dispatch, getState) => {
    ApimGet(
      "api/TpCopernic/Suppliers/" + supplierId + "/Coupa",
      responseJson => {
        dispatch({
          type: Actions.TP_COPERNIC_SUPPLIER_COUPA_GET,
          coupa: responseJson
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.TP_COPERNIC_SUPPLIER_COUPA_ERROR,
          error: error
        });
      }
    );
  };
}

export function SupplierCoupaSave(supplierId, coupa) {
  return dispatch => {
    dispatch({
      type: Actions.TP_COPERNIC_SUPPLIER_COUPA_SAVE_BEGIN
    });

    ApimPost(
      "api/TpCopernic/Suppliers/" + supplierId + "/Coupa",
      coupa,
      res => {
        dispatch({
          type: Actions.TP_COPERNIC_SUPPLIER_COUPA_SAVE_END,
          coupa: res
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.TP_COPERNIC_SUPPLIER_ERROR,
          error
        });
      }
    );
  };
}

export function SupplierCoupaEdit(coupa) {
  // clean coupa object
  const removeProperty = prop => ({ [prop]: _, ...rest }) => rest;

  if (!isNull(coupa.contact)) {
    var contact = coupa.contact;
    if (!isArrayNullOrEmpty(coupa.contact.details)) {
      const removeLocalIdProperty = removeProperty("localId");
      contact.details = contact.details.map(d => {
        return removeLocalIdProperty(d);
      });
    }
  }

  if (!isArrayNullOrEmpty(coupa.containGroups)) {
    const removeActionProperty = removeProperty("action");
    coupa.containGroups = coupa.containGroups.map(c => {
      return c.action === "EXIST" ? removeActionProperty(c) : c;
    });
  }

  // dispatch
  return dispatch => {
    return dispatch({
      type: Actions.TP_COPERNIC_SUPPLIER_COUPA_EDIT,
      coupa: coupa
    });
  };
}

export function SupplierCoupaGroupAdd(containGroup) {
  return dispatch => {
    return dispatch({
      type: Actions.TP_COPERNIC_SUPPLIER_COUPA_GROUP_ADD,
      containGroup
    });
  };
}

/// ------------------------------------------------------------------------------------------------------
/// PAYMENT CHARACTERISTICS ACTIONS
/// ------------------------------------------------------------------------------------------------------

export function SupplierPayCharEdit(payChars) {
  return dispatch => {
    return dispatch({
      type: Actions.TP_COPERNIC_SUPPLIER_PAYCHAR_UPD,
      payChars: payChars
    });
  };
}

export function SupplierPayCharSave(supplierId, payChars) {
  return (dispatch, getState) => {
    dispatch({
      type: Actions.TP_COPERNIC_SUPPLIER_PAYCHAR_SAVE_BEGIN
    });

    const url = "api/TpCopernic/Suppliers/" + supplierId + "/paymentCharacteristics";
    var urlData = payChars
      .filter(payChar => !!payChar.action)
      .map(payChar => {
        return { url, data: payChar };
      });

    ApimMultiplePost(
      urlData,
      responseJson =>
        dispatch({
          type: Actions.TP_COPERNIC_SUPPLIER_PAYCHAR_SAVE_END,
          payChars: responseJson
        }),
      error => {
        console.error(error);
        dispatch({
          type: Actions.TP_COPERNIC_SUPPLIER_ERROR,
          error
        });
      },
      null,
      error => {
        console.error(error);
        dispatch({
          type: Actions.TP_COPERNIC_SUPPLIER_ERROR,
          error
        });
      }
    );
  };
}

/// ------------------------------------------------------------------------------------------------------
/// POINT OF SALES ACTIONS
/// ------------------------------------------------------------------------------------------------------

export function GetPointOfSalesBySupplierId(supplierId) {
  return (dispatch, getState) => {
    ApimGet(
      "api/TpCopernic/Suppliers/" + supplierId + "/PointOfSales",
      responseJson => {
        dispatch({
          type: Actions.TP_COPERNIC_SUPPLIER_POINTOFSALES_GET,
          pointOfSales: responseJson.map(p => {
            return {
              ...p.pointOfSale,
              tpLegalIdentifier: p.tpLegalIdentifier,
              tpAddressCity: p.tpAddressCity,
              tpAddressZipCode: p.tpAddressZipCode
            };
          })
        });
      },
      error => {
        console.error(error);
        dispatch({
          type: Actions.TP_COPERNIC_SUPPLIER_POINTOFSALES_ERR,
          error: error
        });
      }
    );
  };
}

///---------------------------------------------------
/// Get History
///---------------------------------------------------
export function GetSupplierHistory(thirdPartyId, callbackFn, errorFn) {
  ApimGet("api/tpCopernic/Suppliers/" + thirdPartyId + "/histories", callbackFn, errorFn);
}

import React from "react";
import { connect, useDispatch } from "react-redux";
import * as Actions from "../store/actions";

// core components
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import { AntTabs, AntTab, TabPanel } from "../../../components/Common/Tabs";

// tabs
import CardError from "components/Card/CardError";
import TabThirdPartyAddress from "./ThirdPartyDetail/TabThirdPartyAddress";
import TabThirdPartyContact from "./ThirdPartyDetail/TabThirdPartyContact";
import TabThirdPartyGeneral from "./ThirdPartyDetail/TabThirdPartyGeneral";
import TabThirdPartySource from "./ThirdPartyDetail/TabThirdPartySource";
import TabThirdPartyHistory from "./ThirdPartyDetail/TabThirdPartyHistory";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import thirdPartySearchCriteriaStyle from "../../../assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import { CircularProgress } from "@material-ui/core";

// helpers
import { isArrayNullOrEmpty, isNull } from "../../tools";

//lingui
import { Trans } from "@lingui/macro";
import { Warning, CheckCircleOutlined } from "@material-ui/icons";

function ThirdPartyLegalDatas({ thirdPartyCtx, defaultLang, errors }) {
  var dispatch = useDispatch();
  const [tabIdx, setTabIdx] = React.useState(0);

  if (!isNull(thirdPartyCtx)) {
    if (!isNull(thirdPartyCtx.error)) return <CardError error={thirdPartyCtx.error} />;
    if (thirdPartyCtx.saveStatus === "PENDING")
      return (
        <center>
          <p>
            <Trans>Loading in progress</Trans>
          </p>
          <p>
            <CircularProgress />
          </p>
        </center>
      );
    if (thirdPartyCtx.saveStatus === "SUCCESS_RELOAD") {
      var thirdPartyIdentifier = thirdPartyCtx.thirdParty.identifier;
      setTimeout(() => dispatch(Actions.OpenThirdParty(thirdPartyIdentifier, thirdPartyIdentifier, "ThirdPartyLegalDatas")), 2000);
      return (
        <center>
          <p>
            <Trans>Success refreshing</Trans>
          </p>
          <p>
            <CheckCircleOutlined style={{ color: "green" }} />
          </p>
        </center>
      );
    }
  }

  var warnings = thirdPartyCtx.thirdParty.warnings;
  var tabGeneral = <Trans>General</Trans>;
  if (warnings.some(w => w.path.split("/")[0] === "General"))
    tabGeneral = (
      <span>
        <Warning style={{ color: "orange" }} />
        {tabGeneral}
      </span>
    );
  var tabAddress = <Trans>Address</Trans>;
  if (warnings.some(w => w.path.split("/")[0] === "Address"))
    tabAddress = (
      <span>
        <Warning style={{ color: "orange" }} />
        {tabAddress}
      </span>
    );
  var tabCommunication = <Trans>Communication</Trans>;
  if (warnings.some(w => w.path.split("/")[0] === "Communication"))
    tabCommunication = (
      <span>
        <Warning style={{ color: "orange" }} />
        {tabCommunication}
      </span>
    );
  var tabSource = <Trans>Source</Trans>;
  if (warnings.some(w => w.path.split("/")[0] === "Source"))
    tabSource = (
      <span>
        <Warning style={{ color: "orange" }} />
        {tabSource}
      </span>
    );
  var tabHistory = <Trans>History</Trans>;

  return (
    <div className="divContainer">
      <form autoComplete="off">
        <GridContainer>
          <GridItem xs={2} sm={2} md={2}>
            <AntTabs
              orientation="vertical"
              variant="scrollable"
              value={tabIdx}
              onChange={(e, value) => setTabIdx(value)}
              aria-label="Vertical tabs supplier"
            >
              <AntTab label={tabGeneral} />
              <AntTab label={tabAddress} />
              <AntTab label={tabCommunication} />
              <AntTab label={tabSource} />
              <AntTab label={tabHistory} />
            </AntTabs>
          </GridItem>
          <GridItem xs={10} sm={10} md={10}>
            <TabPanel value={tabIdx} index={0}>
              <TabThirdPartyGeneral defaultLang={defaultLang} errors={errors} />
            </TabPanel>
            <TabPanel value={tabIdx} index={1}>
              <TabThirdPartyAddress defaultLang={defaultLang} errors={thirdPartyCtx.error} />
            </TabPanel>
            <TabPanel value={tabIdx} index={2}>
              <TabThirdPartyContact defaultLang={defaultLang} />
            </TabPanel>
            <TabPanel value={tabIdx} index={3}>
              <TabThirdPartySource defaultLang={defaultLang} />
            </TabPanel>
            <TabPanel value={tabIdx} index={4}>
              <TabThirdPartyHistory thirdParty={thirdPartyCtx?.thirdParty} defaultLang={defaultLang} />
            </TabPanel>
          </GridItem>
        </GridContainer>
      </form>
    </div>
  );
}

const TabVerticalStyle = {
  tabs: {
    marginTop: "0.5rem"
  }
};

const mapStateToProps = state => {
  return {
    thirdPartyCtx: !isArrayNullOrEmpty(state.TP_ThirdParties) ? state.TP_ThirdParties[state.TP_ThirdParties.length - 1].thirdPartyCtx : null,
    defaultLang: state.AuthenticationReducer.user.language
  };
};

export default connect(mapStateToProps)(withStyles(thirdPartySearchCriteriaStyle)(ThirdPartyLegalDatas));

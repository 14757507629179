import { Trans } from "@lingui/macro";
import {
  Avatar,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormLabel,
  IconButton,
  Link,
  ListItem,
  Popover,
  TextField,
  Typography
} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { CloudUpload, Delete, Person, Refresh, Warning } from "@material-ui/icons";
import thirdPartySearchCriteriaStyle from "assets/jss/material-dashboard-pro-react/components/thirdPartySearchCriteriaStyle.jsx";
import ItemAutoComplete from "components/Autocomplete/ItemAutoComplete";
import MasterValueAutocomplete from "components/Autocomplete/MasterValueAutocomplete";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardError from "components/Card/CardError";
import CardHeader from "components/Card/CardHeader";
import { ApimGet, ApimGetPromise, ApimUploadFile } from "components/Common/ApimSender";
import DateSelector from "components/Common/DateSelector";
import GetMasterValueLabel from "components/Common/MasterValueLabel";
import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { addDays, isValid } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { GetUsersByProfileTerritories, HasRight } from "services/user/UserHelper";
import {
  CommonCountry,
  LoadMasterValues,
  TpContactType,
  TpPaymentCharacteristicOrigin,
  TpPaymentCharacteristicStatus,
  TpPaymentCharacteristicType,
  TpSisReason
} from "store/MasterValue/MasterValueTypes";
import { distinctBy, isIbanValid, isNull, isNullOrEmpty, nextOpenDay, toDate } from "tools";
import ThirdPartySelectorNew from "../../../components/Common/ThirdPartySelectorNew";
import { FileHelper } from "../../../services/common/FileHelper";
import PaymentCharacteristicContact from "./PaymentCharacteristicContact";

function PaymentCharacteristicLegalDatas({
  // editable
  payChar,
  supplier,
  thirdParty,
  errors,
  // from redux
  masterValues,
  isIbanEditable,
  isDateEditable,
  defaultLang,
  savePaymentCharacteristic,
  classes,
  erts,
  isWfDetail
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [sisCheckState, setSisCheckState] = useState({ isChecking: false });
  const [isMvLoading, setIsMvLoading] = useState("init");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showCloseWithoutSave, setShowCloseWithoutSave] = useState(false);
  const [showErtApprobation, setShowErtApprobation] = useState(false);
  const [state, setState] = useState({});
  const needApprobation =
    HasRight("thirdparty_supplier.edit_iban") && !HasRight("thirdparty_supplier.edit") && !HasRight("thirdparty_workflow.edit_iban");

  const cleanPayChar = pc => {
    if (!pc) return null;

    const { showDialog, ...clean } = pc;
    return clean;
  };

  const storedJsonPayChar = useMemo(() => {
    return JSON.stringify(cleanPayChar(payChar));
  }, [payChar]);

  const updateState = localState => {
    const hasChanged = storedJsonPayChar !== JSON.stringify(cleanPayChar(localState.payChar));
    setState({ ...localState, hasChanged: hasChanged });
  };

  useEffect(() => {
    updateState({ payChar, supplier, thirdParty });
  }, [payChar, supplier, thirdParty]);

  if (isMvLoading === "init") {
    setIsMvLoading("true");
    LoadMasterValues([TpPaymentCharacteristicOrigin, TpPaymentCharacteristicStatus, TpPaymentCharacteristicType, TpContactType, TpSisReason]).then(
      () => setIsMvLoading(null)
    );
  }

  if (!!isMvLoading) {
    return <CircularProgress />;
  }

  const error = code => errors && errors.some(e => e.code === code);

  const countryCode = isNullOrEmpty(state.thirdParty.countryCode) ? state.thirdParty.address_CountryCode : state.thirdParty.countryCode;
  const currentCountry = masterValues[CommonCountry].find(c => c.codeIso2 === countryCode);

  function renderPaymentCharacteristicsChip(row, payCharStatuses) {
    if (!isNullOrEmpty(row)) {
      var splitted = row.split("¤");
      var sisScore = splitted[0];
      var statusCode = splitted[1];
      var label = payCharStatuses.find(i => i.code === statusCode).label;
      if (isNaN(sisScore)) return <Chip style={{ borderStyle: "solid", borderWidth: "2px", borderColor: "#ffbf00" }} label={label} />;
      if (sisScore < 30)
        return (
          <Chip
            style={{ backgroundColor: "#ff2e00" }}
            label={
              <span>
                {sisScore} - {label}
              </span>
            }
          />
        );
      if (sisScore >= 30 && sisScore <= 70)
        return (
          <Chip
            style={{ backgroundColor: "#ffbf00" }}
            label={
              <span>
                {sisScore} - {label}
              </span>
            }
          />
        );
      return (
        <Chip
          style={{ backgroundColor: "#57d500" }}
          label={
            <span>
              {sisScore} - {label}
            </span>
          }
        />
      );
    }
    return <Trans>SIS NotComputed</Trans>;
  }

  function setBeneficiary(thirdParty) {
    if (isNull(thirdParty)) {
      let pc = state.payChar;
      pc.beneficiary = null;
      pc.beneficiaryIdentifier = null;
      updateState({ ...state, payChar: pc });
      return;
    }

    let pc = state.payChar;

    ApimGetPromise(`api/TpCopernic/Beneficiaries/${thirdParty.identifier}`).then(beneficiary => {
      beneficiary.thirdParty = thirdParty;
      pc.beneficiary = beneficiary;
      pc.beneficiaryIdentifier = thirdParty.identifier;
      updateState({ ...state, payChar: pc });
    });
  }

  if (isLoading) {
    return <CircularProgress />;
  }

  const isIban = payChar.paymentCharacteristicTypeCode === "IBAN";
  const returnCountryCode = countryCode => (["PF", "TF", "NC", "BL", "MF", "PM", "WF"].some(s => s === countryCode) ? "FR" : countryCode); // prise en compte des collectivités d'outre mer comme dans le périmètre France

  const extractCountryFromBankAccount = bankAccount => {
    if (isNullOrEmpty(bankAccount) || bankAccount.length < 2) return "";

    return returnCountryCode(bankAccount.substring(0, 2));
  };

  let benefCountryError = false;
  if (
    isIban &&
    !!state.payChar.beneficiary &&
    isIbanValid(state.payChar.bankAccount) &&
    extractCountryFromBankAccount(state.payChar.bankAccount) !== returnCountryCode(state.payChar.beneficiary.thirdPartyCountryCode)
  ) {
    benefCountryError = true;
  }

  var cardCoordBanc = (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>
          <Trans>PaymentCharacteristic_Coordonnes</Trans>
        </h4>
      </CardHeader>
      <CardBody>
        <TextField
          disabled={!isIbanEditable}
          id="bankAccount"
          label={!isIban ? <Trans>PayChar_Bacs_BankAccount</Trans> : <Trans>PayChar_Iban_BankAccount</Trans>}
          value={state.payChar.bankAccount}
          onChange={e => {
            let pc = state.payChar;
            pc.bankAccount = e.target.value;
            updateState({ ...state, payChar: pc });
          }}
          error={error("bankAccount") || benefCountryError}
          fullWidth
        />
        <TextField
          disabled={!isIbanEditable}
          id="bankCode"
          label={!isIban ? <Trans>PayChar_Bacs_BankCode</Trans> : <Trans>PayChar_Iban_BankCode</Trans>}
          value={state.payChar.bankCode}
          onChange={e => {
            let pc = state.payChar;
            pc.bankCode = e.target.value;
            updateState({ ...state, payChar: pc });
          }}
          error={error("bankCode")}
          fullWidth
          onInput={e => {
            if (isIban) {
              e.target.value = e.target.value.toString().slice(0, 11);
            }
          }}
        />
        <TextField
          disabled={!isIbanEditable}
          id="bankLabel"
          label={!isIban ? <Trans>PayChar_Bacs_BankLabel</Trans> : <Trans>PayChar_Iban_BankLabel</Trans>}
          value={state.payChar.bankLabel}
          onChange={e => {
            let pc = state.payChar;
            pc.bankLabel = e.target.value;
            updateState({ ...state, payChar: pc });
          }}
          error={error("bankLabel")}
          fullWidth
        />
        <MasterValueAutocomplete
          disabled
          mvType={TpPaymentCharacteristicOrigin}
          selectedValue={payChar.paymentCharacteristicOriginCode}
          label={<Trans>Origine</Trans>}
        />
      </CardBody>
    </Card>
  );

  var newEndValidityDate = toDate(payChar.startOfValidityDate, defaultLang);
  if (isValid(newEndValidityDate)) {
    newEndValidityDate = addDays(newEndValidityDate, 1);
    newEndValidityDate = nextOpenDay(newEndValidityDate);
  } else {
    newEndValidityDate = null;
  }

  var cardDates = (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>
          <Trans>PaymentCharacteristic_Dates</Trans>
        </h4>
      </CardHeader>
      <CardBody>
        <GridContainer>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>StartOfValidityDate</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <DateSelector
              value={payChar?.startOfValidityDate}
              isEditable={isDateEditable}
              onChange={d => {
                if (!d) return;
                let pc = state.payChar;
                pc.startOfValidityDate = d;
                updateState({ ...state, payChar: pc });
              }}
              error={error("startOfValidityDate")}
              openDaysOnly
              minDate={payChar.minDate}
              defaultLang={defaultLang}
            />
          </GridItem>
          <GridItem xs={4} sm={4} md={4}>
            <FormLabel>
              <Trans>EndOfValidityDate</Trans>
            </FormLabel>
          </GridItem>
          <GridItem xs={8} sm={8} md={8}>
            <DateSelector
              value={payChar?.endOfValidityDate}
              isEditable={isDateEditable}
              onChange={d => {
                let pc = state.payChar;
                pc.endOfValidityDate = d;
                updateState({ ...state, payChar: pc });
              }}
              clearable={!payChar.maxDate}
              error={error("endOfValidityDate")}
              openDaysOnly
              minDate={newEndValidityDate}
              maxDate={payChar.maxDate}
              defaultLang={defaultLang}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  );

  var cardMedia = <></>;

  if (!currentCountry.canCheckSis || needApprobation) {
    var uploadButton = <></>;
    var deleteButton = <></>;

    var documents = "";

    if (!!state.payChar.documentUrls) {
      documents = state.payChar.documentUrls.split("|").map((value, key) => {
        if (isDateEditable) {
          deleteButton = (
            <Button
              justIcon
              round
              simple
              color="info"
              className="like"
              onClick={() => {
                var documents = payChar.documentUrls.split("|");
                const index = documents.indexOf(value);
                if (index > -1) documents.splice(index, 1);

                let pc = state.payChar;
                pc.documentUrls = documents.join("|");
                updateState({ ...state, payChar: pc });
              }}
            >
              <Delete />
            </Button>
          );
        }

        return (
          <ListItem key={`doc_${key}`} style={{ paddingTop: "2px", paddingBottom: "2px" }}>
            <Link
              style={{ cursor: "pointer" }}
              onClick={event => {
                var helper = new FileHelper();
                helper.Download("paychardocuments", value, blob => {
                  var fileParts = value.split(".");
                  // It is necessary to create a new blob object with mime-type explicitly set
                  // otherwise only Chrome works like it should
                  var newBlob = new Blob([blob], { type: "application/" + fileParts[fileParts.length - 1] });

                  // IE doesn't allow using a blob object directly as link href
                  // instead it is necessary to use msSaveOrOpenBlob
                  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(newBlob);
                    return;
                  }

                  // For other browsers:
                  // Create a link pointing to the ObjectURL containing the blob.
                  const data = window.URL.createObjectURL(newBlob);
                  var link = document.createElement("a");
                  link.href = data;
                  link.download = value;
                  link.click();
                  setTimeout(function() {
                    // For Firefox it is necessary to delay revoking the ObjectURL
                    window.URL.revokeObjectURL(data);
                  }, 100);
                });
              }}
            >
              {value}
            </Link>
          </ListItem>
        );
      });
    }

    if (!!state.payChar.waitUploadDocument) {
      uploadButton = <CircularProgress />;
    } else if (!!state.payChar.uploadError) {
      uploadButton = <CardError error={state.payChar.uploadError} />;
    } else if (isDateEditable) {
      uploadButton = (
        <IconButton style={{ color: "#00acc1" }} variant="contained" component="label">
          <CloudUpload />
          <input
            type="file"
            style={{ display: "none" }}
            onChange={event => {
              if (!event.target.files || event.target.files.length === 0) return;

              let pc = state.payChar;
              pc.uploadError = null;
              pc.waitUploadMedia = true;
              updateState({ ...state, payChar: pc });

              ApimUploadFile(
                "api/UploadFile/Upload/paychardocuments",
                event.target.files[0],
                newFile => {
                  var documents = newFile.split("|");
                  var requestDocumentUrl = state.payChar.documentUrl;
                  if (!requestDocumentUrl) requestDocumentUrl = [];
                  requestDocumentUrl.push(documents[0]);
                  pc.documentUrls = requestDocumentUrl.join("|");
                  pc.waitUploadMedia = false;
                  updateState({ ...state, payChar: pc });
                },
                e => {
                  pc.waitUploadMedia = false;
                  pc.uploadError = e;
                  updateState({ ...state, payChar: pc });
                }
              );
            }}
          />
        </IconButton>
      );
    }

    cardMedia = (
      <Card
        style={{
          ...CardInlineStyle.card
        }}
      >
        <CardHeader
          style={{
            ...CardInlineStyle.cardHeader
          }}
          icon
        >
          <h4>
            <Trans>PaymentCharacteristic_Media</Trans>
          </h4>
        </CardHeader>
        <CardBody>
          {documents}
          {uploadButton}
          &nbsp;
          {deleteButton}
        </CardBody>
      </Card>
    );
  }

  function editPayCharContact(p, hasError) {
    updateState({ ...state, payChar: p, hasError: hasError });
  }

  function savePayChar(ertIdentifier, validatorId) {
    let pc = state.payChar;
    let needErtApprobation = pc.sisScore === 0 || pc.sisScore === 9 || pc.sisScore === 70 || pc.sisScore === 90 || pc.sisScore === 99;
    if (needApprobation && canGenerateWF(pc) && (pc.identifier === 0 || needErtApprobation)) {
      // demander un nouveau workflow dans le cas d'une création ou d'une réactivation d'un PayChar KO
      if (!ertIdentifier || !validatorId) {
        setShowErtApprobation(true);
        return;
      }
      pc.workflowErtId = ertIdentifier;
      pc.workflowValidatorId = validatorId;
      updateState({ ...state, payChar: pc });
    }

    setShowErtApprobation(false);
    savePaymentCharacteristic(pc);
  }
  const DialogApprobationSelectErt = ({ show, erts, onClose, onSelectErt }) => {
    const [ertState, setErtState] = useState({ isLoading: false });

    useEffect(() => {
      if (!ertState.ert && erts && erts.length > 0) {
        changeErt(erts[0].identifier);
      }
    }, [erts]);

    const changeErt = ertId => {
      setErtState({ ...ertState, ert: ertId, isLoading: true, validators: null });
      GetUsersByProfileTerritories(
        "MDMS_TPS_PCAPPROVED",
        "",
        ertId,
        v => setErtState({ ...ertState, ert: ertId, isLoading: false, error: null, validators: v }),
        e => setErtState({ ...ertState, ert: ertId, isLoading: false, error: e, validators: [] })
      );
    };

    const changeValidator = validatorId => {
      setErtState({ ...ertState, validatorId: validatorId });
    };
    let ertItems = [];
    if (erts)
      ertItems = distinctBy(erts, "identifier").map(ert => {
        return { code: ert.identifier, label: ert.label };
      });

    let validatorsItems = [];
    if (ertState.validators) {
      validatorsItems = distinctBy(ertState.validators, "identifier").map(valid => {
        return { code: valid.identifier, label: valid.firstName + " " + valid.lastName };
      });
    }

    if (ertState.error) {
      return <CardError error={ertState.error} />;
    }
    return (
      <Dialog open={show} maxWidth={"md"} fullWidth>
        <DialogContent>
          <DialogContentText>
            <div>
              <Trans> ApprobationSelectErt </Trans>
            </div>
            <div>
              <ItemAutoComplete items={ertItems} selectedValue={ertState.ert} isEditable isRequired onValueChange={v => changeErt(v)} prefixCode />
            </div>
            <div>
              <Trans> ApprobationSelectValidator </Trans>
            </div>
            <div>
              <ItemAutoComplete
                items={validatorsItems}
                selectedValue={ertState.validatorId}
                isEditable
                isRequired
                onValueChange={v => changeValidator(v)}
                prefixCode
              />
            </div>
            <div>
              <Trans> ApprobationSelectErt_Validators </Trans> {selectedValidator}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onSelectErt(ertState.ert, ertState.validatorId)} color="info">
            <Trans> Ok </Trans>
          </Button>
          <Button onClick={() => onClose(false)}>
            <Trans> Cancel </Trans>
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  var selectedValidator = "";
  if (state.isLoading) {
    selectedValidator = <CircularProgress />;
  } else if (state.validatorId) {
    selectedValidator = state.validators.map((v, k) => {
      if (v.identifier === state.validatorId)
        return (
          <Chip
            key={k}
            avatar={
              <Avatar>
                <Person />
              </Avatar>
            }
            label={v.firstName + " " + v.lastName + " (" + v.identifier + ")"}
          />
        );
    });
  }
  var cardContact = (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>
          <Trans>PaymentCharacteristic_Contact</Trans>
        </h4>
      </CardHeader>
      <CardBody>
        {/*errorCodes={errorContact}*/}
        <PaymentCharacteristicContact
          payChar={payChar}
          setPayChar={editPayCharContact}
          knownContacts={thirdParty.contacts}
          isEditable={isDateEditable}
          currentCountry={currentCountry}
          errors={errors}
          defaultLang={defaultLang}
          masterValues={masterValues}
        />
      </CardBody>
    </Card>
  );

  var cardSupplier = (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>
          <Trans>PaymentCharacteristic_Supplier</Trans>
        </h4>
      </CardHeader>
      <CardBody>
        {/*<ThirdPartySelector*/}
        {/*    thirdParty={payChar.supplier.thirdParty}*/}
        {/*/>*/}
        <ThirdPartySelectorNew isEditable={false} isLanguageEditable={false} thirdParty={state.thirdParty} />
      </CardBody>
    </Card>
  );

  var cardBeneficiary = (
    <Card
      style={{
        ...CardInlineStyle.card
      }}
    >
      <CardHeader
        style={{
          ...CardInlineStyle.cardHeader
        }}
        icon
      >
        <h4>
          <Trans>PaymentCharacteristic_Beneficiary</Trans>
        </h4>
      </CardHeader>
      <CardBody>
        <ThirdPartySelectorNew
          isEditable={isIbanEditable}
          isLanguageEditable={false}
          isBeneficiary={true}
          thirdParty={!state.payChar.beneficiary ? null : state.payChar.beneficiary.thirdParty}
          setThirdParty={setBeneficiary}
          initialCriterias={{
            localSearch: true,
            types: ["isBeneficiary"],
            beneficiarySupplierSearch: true,
            criteriaCountry: supplier.countryCode
          }}
          clearable
        />
      </CardBody>
    </Card>
  );
  const checkSis = () => {
    if (payChar.identifier === 0) return;

    setSisCheckState({ isChecking: true });
    const relativeUrl = `api/TpCopernic/Suppliers/${payChar.supplierIdentifier}/PaymentCharacteristics/${payChar.identifier}/checkSis/FR`;
    return ApimGet(
      relativeUrl,
      result => {
        setSisCheckState({ isChecking: false });
        setState({ ...state, payChar: { ...result, beneficiary: state.payChar.beneficiary } });
        //setPayChar({ ...payChar, ...result });
      },
      e => {
        setSisCheckState({ isChecking: false, error: e.message });
        throw e;
      }
    );
  };
  var cardSis = <></>;

  if (isIban && currentCountry.canCheckSis && payChar.identifier > 0) {
    var btnCheckSis = "";
    if (sisCheckState.isChecking) {
      btnCheckSis = <CircularProgress />;
    } else {
      btnCheckSis = (
        <IconButton size="small" aria-label="Check IBAN with SIS" onClick={checkSis}>
          <Refresh />
        </IconButton>
      );
    }

    if (sisCheckState.error) {
      btnCheckSis = (
        <>
          {btnCheckSis}
          <Typography color="Error">{sisCheckState.error}</Typography>
        </>
      );
    }

    cardSis = (
      <Card
        style={{
          ...CardInlineStyle.card
        }}
      >
        <CardHeader
          style={{
            ...CardInlineStyle.cardHeader
          }}
          icon
        >
          <h4>
            <Trans>PaymentCharacteristic_SIS</Trans>
          </h4>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={2} sm={2} md={2}>
              <FormLabel>
                <Trans>SIS Score</Trans>
              </FormLabel>
            </GridItem>
            <GridItem xs={2} sm={2} md={2}>
              {renderPaymentCharacteristicsChip(
                state.payChar.sisScore + "¤" + state.payChar.paymentCharacteristicStatusCode,
                masterValues[TpPaymentCharacteristicStatus].map(i => {
                  return { code: i.code, label: GetMasterValueLabel(i, defaultLang) };
                })
              )}
            </GridItem>
            <GridItem xs={3} sm={3} md={3}>
              <FormLabel>
                <Trans>SIS CheckDate</Trans>
              </FormLabel>
            </GridItem>
            <GridItem xs={5} sm={5} md={5}>
              <span>{state.payChar.sisCheckDate}</span>
              {btnCheckSis}
            </GridItem>
            <GridItem xs={2} sm={2} md={2}>
              <FormLabel>
                <Trans>SIS Reason</Trans>
              </FormLabel>
            </GridItem>
            <GridItem xs={10} sm={10} md={10}>
              <span>
                {state.payChar.sisReasons &&
                  state.payChar.sisReasons.split("|").map((v, key) => {
                    let mv = masterValues[TpSisReason].find(mv => mv.code === v);
                    let mvLabel = "";
                    if (mv) {
                      mvLabel = " - " + GetMasterValueLabel(mv, defaultLang);
                    }
                    return (
                      <div key={key}>
                        {v}
                        {mvLabel}
                      </div>
                    );
                  })}
              </span>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }

  var errorPart = "";
  if (!!errors && errors.length > 0 && errors.some(e => !isNullOrEmpty(e.errorMessage))) {
    var errorDetails = errors.map(err => {
      if (!isNullOrEmpty(err.errorMessage)) {
        return <li>{err.errorMessage}</li>;
      }
    });

    const open = Boolean(anchorEl);
    errorPart = (
      <div>
        <Typography
          color="error"
          onClick={event => {
            setAnchorEl(event.currentTarget);
          }}
          style={{ cursor: "pointer" }}
        >
          <Warning color="error" />
          &nbsp;
          <Trans>There are some errors</Trans>
        </Typography>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <Typography color="error">
            <ul>{errorDetails}</ul>
          </Typography>
        </Popover>
      </div>
    );
  }
  function closePaymentCharacteristic(confirmClose) {
    if ((isIbanEditable || isDateEditable) && state.hasChanged && !confirmClose) {
      setShowCloseWithoutSave(true);
    } else {
      setShowCloseWithoutSave(false);
      //setPaymentCharacteristic({ ...paymentCharacteristic, cancel: true });
      let pc = state.payChar;
      pc.cancel = true;
      updateState({ ...state, payChar: pc });
      savePaymentCharacteristic(state.payChar);
    }
  }
  var canGenerateWF = pc => {
    console.log({
      pc,
      oBankAccount: state.oBankAccount,
      oBankCode: state.oBankCode,
      oBankLabel: state.oBankLabel,
      oDocumentUrls: state.oDocumentUrls
    });

    return (
      pc.bankAccount !== state.oBankAccount ||
      pc.bankCode !== state.oBankCode ||
      pc.bankLabel !== state.oBankLabel ||
      pc.documentUrls !== state.oDocumentUrls
    );
  };
  var btnSave =
    (isIbanEditable || isDateEditable) && state.hasChanged ? (
      <Button onClick={() => savePayChar()} disabled={!!state.payChar.waitUploadDocument}>
        <Trans>Save</Trans>
      </Button>
    ) : (
      <></>
    );
  if (isWfDetail) {
    return (
      <div className="divContainer">
        <form autoComplete="off">
          {errorPart}
          <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
              {cardCoordBanc}
              {cardDates}
              {cardMedia}
            </GridItem>
            <GridItem xs={6} sm={6} md={6}>
              {cardSupplier}
              {cardBeneficiary}
              {cardContact}
            </GridItem>
          </GridContainer>
          {cardSis}
        </form>
      </div>
    );
  } else {
    return (
      <>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={state.payChar}
          onClose={() => closePaymentCharacteristic()}
          aria-labelledby="form-dialog-title"
          classes={{ paper: classes.dialogPaper }}
          scroll="paper"
        >
          <DialogTitle>
            <Trans>Detail</Trans> ({state.payChar.paymentCharacteristicTypeCode})
          </DialogTitle>
          <DialogContent
            style={{
              ...DialogInlineStyleDetail.dialogContent
            }}
            dividers={true}
          >
            <div className="divContainer">
              <form autoComplete="off">
                {errorPart}
                <GridContainer>
                  <GridItem xs={6} sm={6} md={6}>
                    {cardCoordBanc}
                    {cardDates}
                    {cardMedia}
                  </GridItem>
                  <GridItem xs={6} sm={6} md={6}>
                    {cardSupplier}
                    {cardBeneficiary}
                    {cardContact}
                  </GridItem>
                </GridContainer>
                {cardSis}
              </form>
            </div>
          </DialogContent>
          <DialogActions>
            {btnSave}
            <Button onClick={() => closePaymentCharacteristic()} color="info" disabled={!!state.payChar.waitUploadDocument}>
              <Trans> Close </Trans>
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={showCloseWithoutSave}>
          <DialogContent>
            <DialogContentText>
              <Trans> ConfirmCloseWithoutSave </Trans>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => closePaymentCharacteristic(true)} color="info">
              <Trans> Yes </Trans>
            </Button>
            <Button onClick={() => setShowCloseWithoutSave(false)}>
              <Trans> No </Trans>
            </Button>
          </DialogActions>
        </Dialog>
        {showErtApprobation && (
          <DialogApprobationSelectErt show={showErtApprobation} onClose={() => setShowErtApprobation(false)} erts={erts} onSelectErt={savePayChar} />
        )}
      </>
    );
  }
}

const CardInlineStyle = {
  card: {
    marginTop: "10px"
  },
  cardHeader: {
    backgroundColor: "rgba(0, 172, 193, 0.6)",
    margin: "0",
    paddingLeft: "10px"
  }
};
const DialogInlineStyleDetail = {
  dialogContent: {
    padding: "0px 10px 0px",
    height: "90%"
  },
  dialogPaper: {
    minHeight: "90vh",
    maxHeight: "90vh"
  }
};
const mapStateToProps = state => {
  return {
    masterValues: state.MasterValueReducer,
    defaultLang: state.AuthenticationReducer.user.language
  };
};

export default connect(mapStateToProps)(withStyles(thirdPartySearchCriteriaStyle)(PaymentCharacteristicLegalDatas));
